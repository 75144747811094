header {
  background-color: #ffffff;
  padding: 20px 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  min-height: var(--nav-bar-height);
  /* max-width: 100vw; */
  display: flex;
  align-items: center;
}
/* if doesn't support backdrop filter, set the background color to pure white */

@supports (backdrop-filter: blur(10px)) {
  header {
    background-color: #ffffffb5;
    backdrop-filter: blur(10px);
  }
}

#mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logoText {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
}

.logoImage {
  width: 48px;
}

.loginAndCreate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.loginName {
  color: #353535;
  font-weight: bold;
  font-size: 16px;
}

.createBtn {
  background-color: #353535;
  border-radius: 27px;
}

.createBtnText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
}

.createName {
  color: #85ffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-right: 8px;
}

.arrowRight {
  width: 14px;
  margin-top: 2px;
}

@media (max-width: 50px) {
  #mainHeader > .loginAndCreate {
    display: none;
    /* flex-direction: column;
    gap: 20px; */
  }
}
