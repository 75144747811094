:root {
  --nav-bar-height: 80px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

body {
  background-color: #fff;
  color: #353535;
  font-family: "Open Sans", sans-serif, Arial, Helvetica;
  font-size: 16px;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
