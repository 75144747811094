#banner {
  background: url("../assets/bannerBackground.png");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(90vh - var(--nav-bar-height));
  position: relative;
}

.bannerContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 80px 20px;
  padding: 40px 24px;
  align-items: center;

  padding-top: calc(var(--nav-bar-height) * 2);
}

.bannerRightSide {
  position: relative;
}

.bannerRight {
  width: 100%;
}

.bannerLeftSide {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
}

.headingBox {
}

.light {
  width: 76px;
  vertical-align: bottom;
}

.bannerText {
  color: #353535;
  line-height: 1.7;
  max-width: 600px;
  font-size: 20px;
}

.bannerHeading {
  font-size: 62px;
  font-weight: bold;
  max-width: 450px;
  color: #353535;
}

.bannerCreateBtn {
  background-color: #353535;
  border-radius: 27px;
  justify-self: self-start;
  margin-top: 40px;
}

.bannerCreateBtnText {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
}

.bannerCreateName {
  color: #85ffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-right: 8px;
}

.arrowRight {
  width: 14px;
  margin-top: 2px;
}

.liner {
  position: absolute;
  height: 80px;
  background: #fff;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 85%
  );
  bottom: 0px;
  left: 0;
  right: 0;
}

/* About */

#about {
  padding: 60px 10px;
  overflow-x: hidden;
}

.trustText {
  text-align: center;
  color: #353535;
  font-size: 22px;
}

.techBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  flex-wrap: wrap;
  gap: 50px 80px;
}

.techBrand {
  width: 45px;
}

.whatis {
  margin-top: 70px;
  padding: 30px 10px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  text-align: center;
  gap: 30px 30px;
  color: #353535;
}

.whatisHeading {
  font-size: 44px;
  font-weight: bold;
}

.whatisText {
  max-width: 820px;
  font-size: 20px;
}

.twoGrids {
  position: relative;
  padding: 40px 10px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.topLeft {
  position: absolute;
  left: 50px;
  top: 0;
  z-index: -1;
}

.bottomRight {
  position: absolute;
  right: 50px;
  bottom: 0;
  z-index: -1;
}

.peopleBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px 80px;
  padding: 30px 10px;
  flex-wrap: wrap;
}

.people {
  background-color: #2e66f9;
  border-radius: 7px;
  max-width: 400px;
  padding: 60px 35px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  color: #fff;
  justify-items: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.p1,
.p2 {
  width: 80px;
  height: 80px;
  border-radius: 1000px;
}

/* Feature */

.feature {
  padding: 40px 16px;
}

.featureBoxOne {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: stretch;
  align-items: center;
  background-color: #132c32;
  padding: 60px 30px;
  border-radius: 7px;
  gap: 30px 80px;
  color: #fff;
}

.fImages {
  width: 100%;
}

.featureOneText {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.featureOneText h1 {
  font-size: 32px;
  font-weight: bold;
  max-width: 420px;
}

.learnmoreBtn {
  text-decoration: underline;
  color: #fff;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
}

.featureBoxTwo {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: stretch;
  align-items: center;
  background-color: #6c9dfb;
  padding: 60px 30px;
  border-radius: 7px;
  gap: 30px 80px;
  color: #fff;
}

.featureBoxTwo h1 {
  font-size: 32px;
  font-weight: bold;
  max-width: 420px;
}

.featureBoxThree {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%230000001F' stroke-width='6' stroke-dasharray='20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 7px;
  background-color: #fff;
  margin-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: stretch;
  align-items: center;
  padding: 60px 30px;
  border-radius: 7px;
  gap: 30px 80px;
  color: #353535;
}

/* Footer */

.footer {
  margin-top: 50px;
  padding: 40px 24px;
}

.getInTouch {
  font-size: 38px;
  font-weight: bold;
  color: #353535;
}

.footerText {
  color: #353535;
  max-width: 520px;
}

.footerBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 100px;
}

.contactUs {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  text-decoration: underline;
}
.mail {
  width: 20px;
}

.footerNav {
  border-top: 1px solid #d7d7d7;
  padding-top: 30px;
  color: #d7d7d7;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px 20px;
}

.footerNavItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media (min-width: 1400px) {
  .bannerRight {
    width: 110%;
  }
}

@media (max-width: 750px) {
  .bannerContent {
    grid-template-columns: 1fr;
  }
  .bannerHeading {
    font-size: 46px;
  }

  .light {
    width: 52px;
    vertical-align: bottom;
  }

  .bannerText {
    color: #353535;
    line-height: 1.7;
    max-width: 600px;
    font-size: 18px;
  }
  .trustText {
    font-size: 18px;
  }

  .whatisHeading {
    font-size: 38px;
    font-weight: bold;
  }

  .whatisText {
    font-size: 16px;
  }

  .people {
    background-color: #2e66f9;
    border-radius: 7px;
    max-width: 400px;
    padding: 40px 25px;
  }

  .topLeft {
    left: 20px;
  }

  .bottomRight {
    right: 20px;
  }

  .featureBoxOne {
    padding: 50px 10px;
    grid-template-columns: 1fr;
  }

  .featureOneText {
    padding: 20px;
  }

  .featureOneText h1 {
    font-size: 24px;
  }

  .featureBoxTwo {
    padding: 50px 10px;
    grid-template-columns: 1fr;
  }

  .featureBoxThree {
    padding: 50px 10px;
    grid-template-columns: 1fr;
  }

  .footerNavItem {
    font-size: 14px;
  }
}
